import React from "react";
import { useAppSlider } from "../../hooks/useAppSlider";
import "swiper/dist/css/swiper.min.css";
import "../../css/slider.css";

export default function AppSlider() {
  useAppSlider();
  return (
    <>
      <h2 className="h2 section-title underline">Clients Review</h2>
      <div className="blog-slider">
        <div
          className="blog-slider__wrp swiper-wrapper"
          id="slider-items-dynamic"
        >
          {/* <div class="blog-slider__item swiper-slide">
  <div class="blog-slider__img">
    <img src="./assets/images/3.png" alt="">
  </div>
  <div class="blog-slider__content">
    <span class="blog-slider__code">Ireland</span>
    <div class="blog-slider__title">Michael Christie</div>
    <div class="blog-slider__text">Tesnova was a dream to work with and has completed all the work to the highest standards! Any one looking for a React Native developer should not hesitate in hiring Tesnova!</div>
    <a href="#" class="blog-slider__button">READ MORE</a>
  </div>
</div>

<div class="blog-slider__item swiper-slide">
  <div class="blog-slider__img">
    <img src="./assets/images/1.png" alt="">
  </div>
  <div class="blog-slider__content">
    <span class="blog-slider__code">Australia</span>
    <div class="blog-slider__title">ksi9302</div>
    <div class="blog-slider__text">Tesnova was very knowledgeable with Apollo client and React architecture and was able to pick out the bug efficiently. I'd recommend Tesnova to anyone looking to solve similar issues. Tesnova has a reasonable price, and the delivery was quick with a helpful manner.</div>
    <a href="#" class="blog-slider__button">READ MORE</a>
  </div>
</div>

<div class="blog-slider__item swiper-slide">
  <div class="blog-slider__img">
    <img src="./assets/images/2.png" alt="">
  </div>
  <div class="blog-slider__content">
    <span class="blog-slider__code">United Kingdom</span>
    <div class="blog-slider__title">Azhar Jamal</div>
    <div class="blog-slider__text">Second time working with Tesnova on some React Native. Perfect really, no complaints.</div>
    <a href="#" class="blog-slider__button">READ MORE</a>
  </div>
</div> */}
        </div>
        <div className="blog-slider__pagination" />
      </div>
    </>
  );
}
