// import "./App.css";
import "./css/style.css";
// import "./js/script";
import { useAppScript } from "./hooks/useAppScript";
import AppSlider from "./components/appSlider/AppSlider";
import AppFooter from "./components/appFooter/AppFooter";
import ContactUs from "./components/contactUs/ContactUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useAppScript();
  return (
    <div id="top">
      {/* 
- #HEADER
*/}
      <header className="header" data-header>
        <div className="container">
          <div className="overlay" data-overlay />
          <a href="#">
            <h1 className="logo">Tesnova</h1>
          </a>
          <nav className="navbar" data-navbar>
            <div className="navbar-top">
              <a href="#" className="logo">
                Tesnova
              </a>
              <button
                className="nav-close-btn"
                aria-label="Close Menu"
                data-nav-close-btn
              >
                <ion-icon name="close-outline" />
              </button>
            </div>
            <ul className="navbar-list">
              <li className="navbar-item">
                <a href="#home" className="navbar-link" data-navbar-link>
                  Home
                </a>
              </li>
              <li className="navbar-item">
                <a href="#about" className="navbar-link" data-navbar-link>
                  About
                </a>
              </li>
              <li className="navbar-item">
                <a href="#services" className="navbar-link" data-navbar-link>
                  Services
                </a>
              </li>
              <li className="navbar-item">
                <a href="#features" className="navbar-link" data-navbar-link>
                  Features
                </a>
              </li>
              {/* 
        <li class="navbar-item">
          <a href="#blog" class="navbar-link" data-navbar-link>Blog</a>
        </li> */}
              <li className="navbar-item">
                <a href="#contact" className="navbar-link" data-navbar-link>
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
          <a href="#contact" className="btn">
            <ion-icon name="chevron-forward-outline" aria-hidden="true" />
            <span>Get A Quote</span>
          </a>
          <button
            className="nav-open-btn"
            aria-label="Open Menu"
            data-nav-open-btn
          >
            <ion-icon name="menu-outline" />
          </button>
        </div>
      </header>
      <main>
        <article>
          {/* 
    - #HERO
  */}
          <section className="hero" id="home">
            <div className="container">
              <div className="hero-content">
                <p className="hero-subtitle">
                  Your Mobile App Development Partner
                </p>
                <h2 className="h2 hero-title">Welcome to Tesnova</h2>
                <p className="hero-text">
                  At Tesnova, we turn your mobile app ideas into reality. Our
                  expert team specializes in creating seamless and innovative
                  mobile apps for both iOS and Android platforms.
                </p>
                <button className="btn">Learn More</button>
              </div>
              <figure className="hero-banner">
                <img
                  src={require("./images/hero-banner.png")}
                  width={694}
                  height={529}
                  loading="lazy"
                  alt="hero-banner"
                  className="w-100 banner-animation"
                />
              </figure>
            </div>
          </section>
          {/* 
    - #ABOUT
  */}
          <section className="section about" id="about">
            <div className="container">
              <figure className="about-banner">
                <img
                  src={require("./images/about-banner.png")}
                  width={700}
                  height={532}
                  loading="lazy"
                  alt="about banner"
                  className="w-100 banner-animation"
                />
              </figure>
              <div className="about-content">
                <h2 className="h2 section-title underline">Why Our Agency</h2>
                <p className="about-text">
                  Discover why Tesnova is the ideal partner for your mobile app
                  development needs. We're driven by innovation, putting clients
                  first, and ensuring top-notch quality. With a dedicated team
                  experienced in React Native, we communicate transparently.
                </p>
                <p className="about-text">
                  adhere to deadlines, and consistently deliver outstanding
                  results. Join us on this exciting journey to bring your app
                  ideas to life.
                </p>
                <ul className="stats-list">
                  <li className="stats-card">
                    <p className="h3 stats-title">100+</p>
                    <p className="stats-text">Satisfied Clients</p>
                  </li>
                  <li className="stats-card">
                    <p className="h3 stats-title">150+</p>
                    <p className="stats-text">Project Lunched</p>
                  </li>
                  <li className="stats-card">
                    <p className="h3 stats-title">3+</p>
                    <p className="stats-text">Years Completed</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          {/* ************************* Slider Start *************************** */}
          <AppSlider />
          {/* ************************* Slider End *************************** */}
          {/* 
    - #SERVICE
  */}
          <section className="section service" id="services">
            <div className="container">
              <h2 className="h2 section-title underline">Our Specialization</h2>
              <ul className="service-list">
                <li>
                  <div className="service-card">
                    <div className="card-icon">
                      <ion-icon name="telescope-outline" />
                    </div>
                    <h3 className="h3 title">
                      Mobile App Development (iOS &amp; Android)
                    </h3>
                    <p className="text">
                      Elevate your business with our top-notch React Native app
                      development. We create high-performance, cost-effective
                      mobile apps for both iOS and Android, ensuring swift
                      market entry.
                    </p>
                    <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline" />
                    </button>
                  </div>
                </li>
                <li>
                  <div className="service-card">
                    <div className="card-icon">
                      <ion-icon name="desktop-outline" />
                    </div>
                    <h3 className="h3 title">
                      UI/UX Design <br />
                      with Love
                    </h3>
                    <p className="text">
                      Transform your app with expert UI/UX design. Our team
                      crafts captivating, user-friendly interfaces that enhance
                      engagement and satisfaction, helping your app stand out.
                    </p>
                    <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline" />
                    </button>
                  </div>
                </li>
                <li>
                  <div className="service-card">
                    <div className="card-icon">
                      <ion-icon name="globe-outline" />
                    </div>
                    <h3 className="h3 title">App Maintenance and Support</h3>
                    <p className="text">
                      Ensure your app's ongoing success with our comprehensive
                      maintenance and support. We provide timely updates, bug
                      fixes, and monitoring to keep your app thriving.
                    </p>
                    <button className="card-btn" aria-label="Show More">
                      <ion-icon name="chevron-forward-outline" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          {/* 
    - #FEATURES
  */}
          <section className="section features" id="features">
            <div className="container">
              <h2 className="h2 section-title underline">Our Features</h2>
              <ul className="features-list">
                <li>
                  <div className="features-card">
                    <div className="icon">
                      <ion-icon name="bulb-outline" />
                    </div>
                    <div className="content">
                      <h3 className="h3 title">Idea &amp; Analysis</h3>
                      <p className="text">
                        We initiate your project with comprehensive idea
                        exploration and analysis. Understanding your vision and
                        objectives is our priority, ensuring a solid foundation
                        for your app's success.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="features-card">
                    <div className="icon">
                      <ion-icon name="color-palette-outline" />
                    </div>
                    <div className="content">
                      <h3 className="h3 title">Designing</h3>
                      <p className="text">
                        Design excellence defines us. Our team creates
                        captivating and user-centric interfaces, ensuring your
                        app's aesthetics match its exceptional functionality
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <figure className="features-banner">
                <img
                  src={require("./images/feautres-banner.png")}
                  width={369}
                  height={318}
                  loading="lazy"
                  alt="Features Banner"
                  className="w-100 banner-animation"
                />
              </figure>
              <ul className="features-list">
                <li>
                  <div className="features-card">
                    <div className="icon">
                      <ion-icon name="code-slash-outline" />
                    </div>
                    <div className="content">
                      <h3 className="h3 title">Development</h3>
                      <p className="text">
                        Our skilled developers transform your ideas into
                        reality. With meticulous attention to detail, we craft
                        robust, high-performance apps that resonate with your
                        audience.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="features-card">
                    <div className="icon">
                      <ion-icon name="rocket-outline" />
                    </div>
                    <div className="content">
                      <h3 className="h3 title">Testing &amp; Lunching</h3>
                      <p className="text">
                        Quality assurance is our mantra. We subject your app to
                        rigorous testing, ensuring it performs flawlessly before
                        its grand debut, guaranteeing a seamless user
                        experience.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          {/* 
    - #BLOG
  */}
          <ContactUs />
        </article>
      </main>
      {/* 
- #FOOTER
*/}
      <AppFooter />
      {/* 
- #GO TO TOP
*/}
      <a
        href="#top"
        className="go-top active"
        aria-label="Go To Top"
        data-go-top
      >
        <ion-icon name="arrow-up-outline" />
      </a>
      {/* 
- custom js link
*/}
      {/* 
- ionicon link
*/}
      <ToastContainer />
    </div>
  );
}

export default App;
