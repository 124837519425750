import { useState } from "react";
import { db } from "../api/firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";

export function useContactUs() {
  const [isLoading, setLoading] = useState(false);
  const saveToFirestore = async (requestPayload) => {
    setLoading(true);
    try {
      const messagesCollection = collection(db, "messages");
      // Data to be added
      const dataToAdd = {
        ...requestPayload,
        timestamp: new Date(),
      };

      // Add the data to the collection
      const docRef = await addDoc(messagesCollection, dataToAdd);
      resetFields();
      toast.success(
        "Thank you for reaching out! We will respond to you shortly.",
        {
          position: "top-right",
        }
      );
      console.log("Document added with ID: ", docRef.id);
    } catch (error) {
      toast.error("An error occurred. Please try your request again.", {
        position: "top-right",
      });
      console.error("Error saving message: ", error);
    } finally {
      setLoading(false);
    }
  };

  function resetFields() {
    document.getElementById("tesnova-form").reset();
  }
  function onSubmit(event) {
    event.preventDefault();
    const { email, name, message } = event.target;
    const requestPayload = {
      email: email.value,
      name: name.value,
      message: message.value,
    };
    saveToFirestore(requestPayload);
  }
  return { isLoading, onSubmit };
}
