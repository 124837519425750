import { useEffect } from "react";

export function useAppScript() {
  useEffect(() => {
    const navOpenBtn = document.querySelector("[data-nav-open-btn]");
    const navbar = document.querySelector("[data-navbar]");
    const navCloseBtn = document.querySelector("[data-nav-close-btn]");
    const overlay = document.querySelector("[data-overlay]");

    const elemArr = [navCloseBtn, overlay, navOpenBtn];
    function onClickMenu() {
      navbar.classList.toggle("active");
      overlay.classList.toggle("active");
    }

    for (let i = 0; i < elemArr.length; i++) {
      if (elemArr[i]) {
        elemArr[i].addEventListener("click", onClickMenu);
      }
    }

    /**
     * toggle navbar & overlay when click any navbar-link
     */

    const navbarLinks = document.querySelectorAll("[data-navbar-link]");

    for (let i = 0; i < navbarLinks.length; i++) {
      if (navbarLinks[i]) {
        navbarLinks[i].addEventListener("click", onClickMenu);
      }
    }

    /**
     * header & go-top-btn active
     * when window scroll down to 400px
     */

    const header = document.querySelector("[data-header]");
    const goTopBtn = document.querySelector("[data-go-top]");

    window.addEventListener("scroll", function () {
      if (window.scrollY >= 400) {
        header.classList.add("active");
        goTopBtn.classList.add("active");
      } else {
        header.classList.remove("active");
        goTopBtn.classList.remove("active");
      }
    });
    return () => {
      for (let i = 0; i < elemArr.length; i++) {
        if (elemArr[i]) {
          elemArr[i].removeEventListener("click", onClickMenu);
        }
      }
      for (let i = 0; i < navbarLinks.length; i++) {
        if (navbarLinks[i]) {
          navbarLinks[i].removeEventListener("click", onClickMenu);
        }
      }
    };
  }, []);
  return {};
}
