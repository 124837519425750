export default function AppFooter() {
  return (
    <footer className="footer">
      <div className="footer-top section">
        <div className="container">
          <div className="footer-brand">
            <a href="#" className="logo">
              Tesnova
            </a>
            <p className="text">
              At Tesnova, we turn your mobile app ideas into reality. Our expert
              team specializes in creating seamless and innovative mobile apps
              for both iOS and Android platforms.
            </p>
            <ul className="social-list">
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/tesnova.solutions"
                  className="social-link"
                >
                  <ion-icon name="logo-facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/tesnova.solutions"
                  className="social-link"
                >
                  <ion-icon name="logo-instagram" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@tesnova.solutions"
                  className="social-link"
                >
                  <ion-icon name="logo-tiktok" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/tesnova"
                  className="social-link"
                >
                  <ion-icon name="logo-linkedin" />
                </a>
              </li>
            </ul>
          </div>
          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Our links</p>
            </li>
            <li>
              <a href="#" className="footer-link">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="footer-link">
                About Us
              </a>
            </li>
            <li>
              <a href="#services" className="footer-link">
                Services
              </a>
            </li>
            <li>
              <a href="#features" className="footer-link">
                Features
              </a>
            </li>
            <li>
              <a href="#contact" className="footer-link">
                Contact Us
              </a>
            </li>
          </ul>
          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Our Services</p>
            </li>
            <li>
              <a href="#" className="footer-link">
                Strategy &amp; Research
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                App Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Web Development
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Web Solution
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                App Design
              </a>
            </li>
          </ul>
          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Other links</p>
            </li>
            <li>
              <a href="#" className="footer-link">
                FAQ
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="#" className="footer-link">
                Support
              </a>
            </li>
          </ul>
          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Contact Us</p>
            </li>
            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="call" />
              </div>
              <div>
                <a href="tel:+923030620582" className="footer-item-link">
                  +92 303-062-0582
                </a>
                {/* <a href="tel:+2486871365" class="footer-item-link"
              >+248-687-1365</a
            > */}
              </div>
            </li>
            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="mail" />
              </div>
              <div>
                <a href="mailto:info@tesnova.com" className="footer-item-link">
                  info@tesnova.com
                </a>
                {/* <a href="mailto:help@tesnova.com" class="footer-item-link"
              >help@tesnova.com</a
            > */}
              </div>
            </li>
            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="location" />
              </div>
              <address className="footer-item-link">
                Pontiac, Michigan, United States of America
              </address>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyright">
          © 2024{" "}
          <a href="#" className="copyright-link">
            Tesnova
          </a>
          . All Right Reserved
        </p>
      </div>
    </footer>
  );
}
