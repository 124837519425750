import { useEffect } from "react";
import Swiper from "swiper";

const data = [
  {
    user_name: "Michael Christie",
    country: "Ireland",
    reviewText:
      "Tesnova was a dream to work with and has completed all the work to the highest standards! Any one looking for a React Native developer should not hesitate in hiring Tesnova!",
  },
  {
    user_name: "ksi9302",
    country: "Australia",
    reviewText:
      "Tesnova was very knowledgeable with Apollo client and React architecture and was able to pick out the bug efficiently. I'd recommend Tesnova to anyone looking to solve similar issues. Tesnova has a reasonable price, and the delivery was quick with a helpful manner.",
  },
  {
    user_name: "Azhar Jamal",
    country: "United Kingdom",
    reviewText:
      "Second time working with Tesnova on some React Native. Perfect really, no complaints.",
  },
  {
    user_name: "Azhar Jamal",
    country: "United Kingdom",
    reviewText: `It was nice working with the developer briefly. Here's what I can say you can expect from Tesnova:
  
        - great communication
        - brings fresh ideas to the table
        - thinks outside the box
        - thorough demonstrations
        - clean code
        
        We are looking to have him again and collaborate in the future`,
  },
  {
    user_name: "David Miller",
    country: "United States",
    reviewText:
      "Awesome Team down to earth understood all my requirements went all in and beyond. will definitely come back to him for more future projects. You were an EXCELLENT to work with. You haveGreat support and great experience. Thank you again!",
  },
  {
    user_name: "Saba",
    country: "Canada",
    reviewText:
      "BEST TEACHER EVER!!!!! Seriously, I was not expecting to have such an amazing teacher. -He communicates perfectly, he explain the subject with passion and exceeding what you actually think a programming lesson will be like. -His delivery method and knowledge are superb! - Thank you so much !",
  },
  {
    user_name: "Tom",
    country: "United States",
    reviewText:
      "I had a great experience with Tesnova. Tesnova was able to work through a difficult codebase left by a previous developer and get everything fixed.",
  },
];

function generateReviews() {
  var i = 0;
  let innerHTML = "";
  for (i = 0; i < data.length; i++) {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    const img = require(`../images/${randomNumber}.png`);
    const item = data[i];
    const reviewBody = `<div class="blog-slider__item swiper-slide">
      <div class="blog-slider__img">
        <img src="${img}" alt="">
      </div>
      <div class="blog-slider__content">
        <span class="blog-slider__code">${item.country}</span>
        <div class="blog-slider__title">${item.user_name}</div>
        <div class="blog-slider__text">${item.reviewText}</div>
        <a href="#" class="blog-slider__button">READ MORE</a>
      </div>
    </div>`;
    innerHTML += reviewBody;
  }
  document.getElementById("slider-items-dynamic").innerHTML = innerHTML;
}

export function useAppSlider() {
  useEffect(() => {
    generateReviews();

    new Swiper(".blog-slider", {
      spaceBetween: 30,
      effect: "fade",
      loop: true,
      mousewheel: {
        invert: false,
      },
      // autoHeight: true,
      pagination: {
        el: ".blog-slider__pagination",
        clickable: true,
      },
    });
  }, []);
}
