import { useContactUs } from "../../hooks/useContactUs";

export default function ContactUs() {
  const { isLoading, onSubmit } = useContactUs();
  return (
    <section className="section blog" id="contact">
      <div className="container">
        <h2 className="h2 section-title underline">Contact Us</h2>
        {/* <ul class="blog-list">
<li>
  <div class="blog-card">
    <figure class="banner">
      <a href="#">
        <img
          src={require("./images/blog-1.jpg"
          width="750"
          height="350"
          loading="lazy"
          alt="Vestibulum massa arcu, consectetu pellentesque scelerisque."
          class="img-cover"
        />
      </a>
    </figure>

    <div class="content">
      <h3 class="h3 title">
        <a href="#">
          Vestibulum massa arcu, consectetu pellentesque
          scelerisque.
        </a>
      </h3>

      <p class="text">
        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
        massa et, consequat laoreet erat nam ac imperdiet.
      </p>

      <div class="meta">
        <div class="publish-date">
          <ion-icon name="time-outline"></ion-icon>

          <time datetime="2022-03-07">7 March, 2022</time>
        </div>

        <button class="comment" aria-label="Comment">
          <ion-icon name="chatbubble-outline"></ion-icon>

          <data value="15">15</data>
        </button>

        <button class="share" aria-label="Share">
          <ion-icon name="share-social-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</li>

<li>
  <div class="blog-card">
    <figure class="banner">
      <a href="#">
        <img
          src={require("./images/blog-2.jpg"
          width="750"
          height="350"
          loading="lazy"
          alt="Quisque egestas iaculis felis eget placerat ut pulvinar mi."
          class="img-cover"
        />
      </a>
    </figure>

    <div class="content">
      <h3 class="h3 title">
        <a href="#">
          Quisque egestas iaculis felis eget placerat ut pulvinar
          mi.
        </a>
      </h3>

      <p class="text">
        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
        massa et, consequat laoreet erat nam ac imperdiet.
      </p>

      <div class="meta">
        <div class="publish-date">
          <ion-icon name="time-outline"></ion-icon>

          <time datetime="2022-03-07">7 March, 2022</time>
        </div>

        <button class="comment" aria-label="Comment">
          <ion-icon name="chatbubble-outline"></ion-icon>

          <data value="15">15</data>
        </button>

        <button class="share" aria-label="Share">
          <ion-icon name="share-social-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</li>

<li>
  <div class="blog-card">
    <figure class="banner">
      <a href="#">
        <img
          src={require("./images/blog-3.jpg"
          width="750"
          height="350"
          loading="lazy"
          alt="Fusce sem ligula, imperdiet sed nisi sit amet, euismod posuere."
          class="img-cover"
        />
      </a>
    </figure>

    <div class="content">
      <h3 class="h3 title">
        <a href="#">
          Fusce sem ligula, imperdiet sed nisi sit amet, euismod
          posuere.
        </a>
      </h3>

      <p class="text">
        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
        massa et, consequat laoreet erat nam ac imperdiet.
      </p>

      <div class="meta">
        <div class="publish-date">
          <ion-icon name="time-outline"></ion-icon>

          <time datetime="2022-03-07">7 March, 2022</time>
        </div>

        <button class="comment" aria-label="Comment">
          <ion-icon name="chatbubble-outline"></ion-icon>

          <data value="15">15</data>
        </button>

        <button class="share" aria-label="Share">
          <ion-icon name="share-social-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</li>

<li>
  <div class="blog-card">
    <figure class="banner">
      <a href="#">
        <img
          src={require("./images/blog-4.jpg"
          width="750"
          height="350"
          loading="lazy"
          alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
          class="img-cover"
        />
      </a>
    </figure>

    <div class="content">
      <h3 class="h3 title">
        <a href="#">
          Donec feugiat mollis nisi in dignissim. Morbi
          sollicitudin quis.
        </a>
      </h3>

      <p class="text">
        Sed quis sagittis velit. Aliquam velit eros, bibendum ut
        massa et, consequat laoreet erat nam ac imperdiet.
      </p>

      <div class="meta">
        <div class="publish-date">
          <ion-icon name="time-outline"></ion-icon>

          <time datetime="2022-03-07">7 March, 2022</time>
        </div>

        <button class="comment" aria-label="Comment">
          <ion-icon name="chatbubble-outline"></ion-icon>

          <data value="15">15</data>
        </button>

        <button class="share" aria-label="Share">
          <ion-icon name="share-social-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</li>
</ul> */}
        <section className="contact">
          {/* <h1>Contact Us</h1> */}
          <form id="tesnova-form" className="form" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                rows={4}
                required
                defaultValue={""}
              />
            </div>
            <div className="form-group">
              <button type="submit">
                {isLoading ? "Please wait..." : "Submit"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
  );
}
